export function changePath(url: string, slug = ''): string {
  const path = url
    .replace(
      // eslint-disable-next-line no-useless-escape
      new RegExp(/(.*)?\/([^\/\.]+)(?:\.([^\/\.]+))?\/?$/i),
      `$2`
    )
    .replace(new RegExp(`([^"]*)`), `${slug}$1`);
  return path;
}
