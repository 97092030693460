import React, { FC, ReactElement } from 'react';
import { Breadcrumbs } from '../../common/Breadcrumbs';

import styles from './styles.module.scss';

interface IProps {
  title: ReactElement | null;
  description: ReactElement | null;
}

const SpeechesHeader: FC<IProps> = ({ title, description }) => (
  <div className={styles.header}>
    <Breadcrumbs title={title} />
    <h2 className={styles.header__title}>{title}</h2>
    <p className={styles.header__description}>{description}</p>
  </div>
);

export { SpeechesHeader };
