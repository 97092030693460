import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import { PostCard } from '../../blog/BlogPostCard';
import { ISpeechesPostsQuery } from '../../../queries/posts/types';
import { t } from '../../../i18n';
import { formatIsoDateToHuman } from '../../../utils/date';
import { changePath } from '../../../utils/hooks';

type IProps = {
  data: ISpeechesPostsQuery;
  from: string;
  locale: string;
  tag: string;
};

const SpeechesList: FC<IProps> = ({ from, data, locale = 'en', tag = '' }) => {
  const prevPosts = useMemo(
    () =>
      data?.prev.edges
        .map((edge) => edge.node)
        .filter(
          (node) =>
            !tag ||
            (node.frontmatter.tags && node.frontmatter.tags.includes(tag))
        ),
    [locale, data.prev, tag]
  );

  const nextPosts = useMemo(
    () =>
      data?.next.edges
        .map((edge) => edge.node)
        .filter(
          (node) =>
            !tag ||
            (node.frontmatter.tags && node.frontmatter.tags.includes(tag))
        ),
    [locale, data.next, tag]
  );

  if (!prevPosts.length && !nextPosts.length) {
    return (
      <div
        className={classnames('content', styles.wrap)}
        style={{ textAlign: 'center' }}
      >
        <p>{t('speeches.process')}</p>
        <p>{t('speeches.come_back')}</p>
      </div>
    );
  }

  return (
    <div className={classnames('content', styles.wrap)}>
      {nextPosts.length === 0 ? (
        <></>
      ) : (
        <div className={styles.grid}>
          <span>{t('speeches.next')}</span>
          {nextPosts.map(
            (
              {
                fileAbsolutePath,
                frontmatter: { title, description, featuredImage, date, url },
              },
              i
            ) => (
              <div
                key={changePath(fileAbsolutePath)}
                className={styles.speechContainer}
              >
                <PostCard
                  slug={changePath(fileAbsolutePath)}
                  title={title}
                  featuredImage={featuredImage}
                  date={formatIsoDateToHuman(date, locale)}
                  url={url}
                  from={from}
                  description={description}
                />
              </div>
            )
          )}
        </div>
      )}
      <div className={styles.grid}>
        <span>{t('speeches.prev')}</span>
        {prevPosts.map(
          ({
            fileAbsolutePath,
            frontmatter: { title, description, featuredImage, date, url },
          }) => (
            <div
              key={changePath(fileAbsolutePath)}
              className={styles.speechContainer}
            >
              <PostCard
                slug={changePath(fileAbsolutePath)}
                title={title}
                featuredImage={featuredImage}
                date={formatIsoDateToHuman(date, locale)}
                url={url}
                from={from}
                description={description}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export { SpeechesList };
