import React, { FC, Fragment, ReactElement } from 'react';
import { useLocation } from '@reach/router';
import styles from './styles.module.scss';
import { t } from '../../../i18n';
import { TLink } from '../../../i18n/TLink';
import { useFromBreadcrumbs } from '../../../constants/hooks';

interface IProps {
  title: string | ReactElement | null;
  textColor?: string;
}

const Breadcrumbs: FC<IProps> = ({ title, textColor }) => {
  let breadcrumbs = useFromBreadcrumbs();
  const location = useLocation();

  if (location.pathname.indexOf('cases') !== -1) {
    breadcrumbs = [{ title: 'menu.portfolio', url: '/cases' }];
  }

  if (location.pathname.indexOf('directions') !== -1) {
    breadcrumbs = [{ title: 'menu.directions', url: '/directions' }];
  }

  if (location.pathname.indexOf('internship') !== -1) {
    breadcrumbs = [{ title: 'menu.about', url: '/' }];
  }

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map((item, i) => (
        <Fragment key={item.url}>
          <div className={styles.breadcrumbs__item}>
            <TLink to={item.url} style={{ color: textColor }}>
              {t(item.title)}
            </TLink>
          </div>

          <div
            className={styles.breadcrumbs__separator}
            style={{ color: textColor }}
          >
            /
          </div>
        </Fragment>
      ))}

      <div className={styles.breadcrumbs__current} style={{ color: textColor }}>
        {title}
      </div>
    </div>
  );
};

export { Breadcrumbs };
