import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  date: string;
  slug?: string;
  title: string;
  description?: string;
  featuredImage: string;
  from?: string;
  url?: string;
}

const PostCard: FC<IProps> = ({
  slug,
  title,
  featuredImage,
  date,
  description,
  from,
  url,
}) => {
  const ref = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);
  const prefix = usePathPrefix();
  const onLoad = useCallback(() => setLoaded(true), [setLoaded]);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.addEventListener('load', onLoad);
    ref.current.src = `${prefix}${featuredImage}`;
  }, [ref.current]);

  return (
    <TLink
      to={url || ''}
      className={classnames(styles.post, {
        [styles.loaded]: loaded,
      })}
      key={title}
    >
      <div
        className={styles.post__thumb}
        style={{ backgroundImage: `url('${prefix}${featuredImage}')` }}
      >
        <svg viewBox="0 0 100 50" width="100%" />
        <img onLoad={onLoad} ref={ref} alt="" />
      </div>

      <div className={styles.post__info}>
        <div className={styles.post__subtitle}>{date}</div>
        <div className={styles.post__title}>{title}</div>
        {description && (
          <div className={styles.post__description}>{description}</div>
        )}
      </div>
    </TLink>
  );
};

export { PostCard };
